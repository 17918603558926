import React, {useState, useEffect} from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import NavBar from "./NavBar";
import Header from './Header';
import Header2 from './Header2';
import "./style/Table.css";

// Import API_URL from config.js
const config = require("../config");
const API_URL = config.API_URL;

const EPackRequests = ({user}) => {
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [requestedEPacks, setRequestedEPacks] = useState([]);
    const [approvedUpdate, setApprovedUpdate] = useState([]);

    useEffect(() => {
        fetchOptions();
    }, []);

    // Fetch EPack lowstock requests to be displayed
    const fetchOptions = async () => {
        try {
            const response = await axios.post(`${API_URL}/epacks/lowstock-query`);
            const options = response.data.map((location) => ({
                label: location.location_name,
                pack_id: location.pack_id,
                location_id: location.location_id,
                requested_stock_level: location.requested_stock_level,
            }));
            setRequestedEPacks(options);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };

    // selected request will be assigned via onClick event and popup will be triggered
    const beginAdd = (item) => {
        setApprovedUpdate(item);
        setIsModalOpen(true);
    }

    // Used to pass new quantity request data to be added onto current EPack amounts for the given location
    const handleSubmit = async (item) => {
        //item.preventDefault();
        try{
            const input = await axios.put(
                `${API_URL}/epacks/update/add`,
                // Prepare the data to match the API's expected format
                {
                    add_quantity: item.requested_stock_level,
                    pack_id: item.pack_id,
                    location_id: item.location_id,
                }
            );
            console.log("Request has been successfully added", input);

            setUpdateSuccess(true);
            //setApprovedUpdate(null);
            setTimeout(() => {
                setUpdateSuccess(false);
            }, 5000);
        }catch (error) {
            console.error("Error:", error);
        }
    }

    // Used to then delete the request once it has been successfully processed 
    const handleDelete = async (item) => {
        try{
            const response = await axios.delete(
                `${API_URL}/epacks/update/delete`,
                {
                    data: {
                        requested_stock_level: item.requested_stock_level,
                        pack_id: item.pack_id,
                        location_id: item.location_id,
                    }
                }
            );
            console.log("Request has been successfully deleted", response);

            setDeleteSuccess(true);
            setApprovedUpdate([]);
            setTimeout(() => {
                setDeleteSuccess(false);
            }, 5000);
        }catch(error){
            console.log("error", error);
        }
    }

    // If request is approved, handleSubmit and handleDelete will be called
    const confirmChange = (item) => {
        handleSubmit(item);
        handleDelete(item);
        setTimeout(() => {
            //handleDelete(item);
            setIsModalOpen(false);
            fetchOptions();
        }, 5000);
    }

    return (
        <div>
            <Header />
            <Header2 />
            <NavBar user={user} />
            <h1>Requested EPacks</h1>

            <div className="dynamic-box">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Quantity</th>
                            <th>Confirm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requestedEPacks &&
                            requestedEPacks.map((dataItem, index) => (
                                <tr key={index}>
                                    <td>{dataItem.label}</td>
                                    <td>{dataItem.requested_stock_level}</td>
                                    <td>
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                color: "#F1B82D",
                                                fontSize: "27px",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            onClick={() => beginAdd(dataItem)}
                                        >
                                            &#x2714;   
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {deleteSuccess && updateSuccess && <p>Update successful!</p>}
            </div>

            <Popup
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                modal
                contentStyle={{
                    background: "#f0f0f0",
                    borderRadius: "25px",
                    padding: "20px",
                    border: "solid 2px #404040",
                    color: "#262626",
                }}
                >

                <div>
                    <p style={{color: "#262626"}}>
                        Confirm Add?
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <button
                            onClick={() => confirmChange(approvedUpdate)}
                            style={{
                                backgroundColor: "#404040",
                                color: "#fff",
                                border: "none",
                                padding: "8px 16px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                            }}
                        >
                            Yes
                        </button>
                        <button
                            onClick={() => setIsModalOpen(false)}
                            style={{
                                backgroundColor: "#404040",
                                color: "#fff",
                                border: "none",
                                padding: "8px 16px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "14px",
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );

};

export default EPackRequests;