import React, {useState, useEffect} from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Header from './Header';
import Header2 from './Header2';
import "./style/Table.css";

// Import API_URL from config.js
const config = require("../config");
const API_URL = config.API_URL;

const PantryAddNewInventory = ({user}) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [addQuantity, setAddQuantity] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        fetchOptions();
    }, []);

    const fetchOptions = async () => {
        try {
            const response = await axios.post(`${API_URL}/items/query`, {
                location_id: 1,
            });
            const options = response.data.map((item) => ({
                label: item.item_name,
            }));
            setOptions(options);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!selectedOption || !addQuantity) {
                console.error("Please enter an item and a quantity.");
                return;
            }

            //const present = options.includes(selectedOption);
            console.log(options);

            // Trim and convert to lower case for case-insensitive comparison
            const trimmedOption = selectedOption.trim().toLowerCase();
            
            // Ensure options array contains only strings
            const present = options.some(option => 
                option.label.toLowerCase() === trimmedOption
            );

            if(present){
                setErrorMessage(true);
                console.error("That item is alreay added, please specify a new item.");
                setAddQuantity("");
                setSelectedOption("");
                setTimeout(() => {
                    setErrorMessage(false);
                }, 3000);
                return;
            }
            
            // Calculate new stock level
            const currentStockLevel = selectedOption.new_stock_level || 0;
            const newStockLevel = currentStockLevel + parseInt(addQuantity);

            // Prepare the data to match the API's expected format
            const dataToUpdate = {
                label: selectedOption,
                location_id: 1,
                new_stock_level: newStockLevel,
            };
            
            // Perform PUT request to add new item and current stock level 
            await axios.put(`${API_URL}/items/update/new`, dataToUpdate);

            // Display success message
            setUpdateSuccess(true);

            // Clear the dropdown and quantity fields
            setSelectedOption(null);
            setAddQuantity("");
            setSelectedOption("");

            // Reset success message after a delay
            setTimeout(() => {
                setUpdateSuccess(false);
                fetchOptions();
            }, 3000);
            
        } catch (error) {
            console.error("Error:", error);
        }
    };
    
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "4px solid #dbd5cd",
            borderRadius: "0px",
            backgroundColor: "white",
            color: "#9f9c97",
            fontFamily:
                'Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            fontSize: "14px",
            fontWeight: "400",
            textAlign: "center",
            willChange: "transform",
            "&:hover": {border: "4px solid #dbd5cd"},
        }),
    };
    
    return (
        <div>
            <Header />
            <Header2 />
            <NavBar user={user} />
            <h1>Pantry Add New Inventory</h1>
            <div className="dynamic-box">
                <div className="form">
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            id="selectedOption"
                            name="selectedOption"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            placeholder="Add New Item"
                            required
                            styles={customStyles}
                        />
                        <input
                            type="text"
                            id="addQuantity"
                            name="addQuantity"
                            value={addQuantity}
                            onChange={(e) => setAddQuantity(e.target.value)}
                            placeholder="Quantity"
                            required
                        />
                        <button className="button-submit-update" type="submit">
                            Add
                        </button>
                    </form>
                    {errorMessage && <p style={{ color: 'red'}}>That item is alreay added, please specify a new item.</p>}
                    {updateSuccess && <p>Successfully Added!</p>}
                </div>
            </div>
        </div>
    );
};

export default PantryAddNewInventory;


