// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.signin-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.signin-button {
    font-size: 1.2em;
    padding: 10px 20px;
}

.signin-logo {
    width: 700px;
    height: auto;
    margin-bottom: 50px;
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/SignIn.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".signin-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f5f5f5;\n}\n\n.signin-title {\n    font-size: 2em;\n    margin-bottom: 20px;\n}\n\n.signin-button {\n    font-size: 1.2em;\n    padding: 10px 20px;\n}\n\n.signin-logo {\n    width: 700px;\n    height: auto;\n    margin-bottom: 50px;\n    margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
