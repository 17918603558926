import React, {useState, useEffect} from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Header from './Header';
import Header2 from './Header2';
import "./style/Table.css";

// Import API_URL from config.js
const config = require("../config");
const API_URL = config.API_URL;

const EPackLowInStock = ({user}) => {
    const [LowInStockData, setLowInStockData] = useState([]);

    // Displays all the EPack locations that have fallen below their set threshhold 
    useEffect(() => {
        const getLowStockData = async () => {
            try{
                const apiUrl = await axios.post(`${API_URL}/epacks/low-stock`,{
                    threshold: 5,
                });

                const response = apiUrl.data.map((location) => ({
                    label: location.location_name,
                    value: location.pack_id,
                    location_id: location.location_id,
                    quantity: location.quantity,
                }));

                setLowInStockData(response);
                //console.log(response);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        getLowStockData();
    }, []);

    return (
        <div>
            <Header />
            <Header2 />
            <NavBar user={user} />
            <h1>EPacks Low In Stock</h1>

            <div className="dynamic-box">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LowInStockData &&
                            LowInStockData.map((dataItem, index) => (
                                <tr key={index}>
                                    <td>{dataItem.label}</td>
                                    <td>{dataItem.quantity}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EPackLowInStock;