import React, {useState, useEffect} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import "./style/NavBar.css";

// Import API_URL from config.js
const config = require("../config");

const NavBar = ({user}) => {

    return (
        <nav className="navbar">
            <ul className="nav-list">
                <li className="nav-item">
                    <Link to="/">Home</Link>
                </li>
                {(user.role === "admin" || user.role === "volunteer") && (
                    <li className="nav-item dropdown">
                        <Link to="/pages/PantryMenu">Pantry</Link>
                        <div className="dropdown-content">
                            <Link to="/pages/PantryLowInStock">
                                Low In Stock
                            </Link>
                            <Link to="/pages/PantryCurrentInventory">
                                Current Inventory
                            </Link>
                            <Link to="/pages/PantryAddNewInventory">
                                Add New Inventory
                            </Link>
                        </div>
                    </li>
                )}
                <li className="nav-item dropdown">
                    <Link to="/pages/EPackMenu">Emergency Packs</Link>
                    <div className="dropdown-content">
                        <Link to="/pages/EPackLowInStock">
                            Low In Stock
                        </Link>
                        <Link to="/pages/EPackLocations">
                            E-Pack Locations
                        </Link>
                        <Link to="/pages/EPackRequests">
                            EPack Requests
                        </Link>
                    </div>
                </li>
                {user.role === "admin" && (
                    <li className="nav-item">
                        <Link to="/pages/Admin">Admin</Link>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;
